import LazyLoad from './lazy-load.js';

/**
 * [InspirationSearchbar description]
 * @param {Object} elements
 *        @param {jqueryObject} list              // Articles container
 *        @param {jqueryObject} listItems         // Articles
 *        @param {jqueryObject} navContainer      // Nav container
 *        @param {jqueryObject} navToggle         // Nav toggle trigger
    *        @param {jqueryObject} navSearchInput    // Nav input type="search"
 *        @param {jqueryObject} navTabItems       // Nav tabs
 */
function InspirationSearchbar(elements) {
    this.isOpen = false;
    this.elements = elements;
    this.filterValues = {
        type: '',
        name: ''
    };
    this.activeFilters = {
        type: false,
        name: false
    }
    this.init();
}

InspirationSearchbar.prototype = {

    init: function() {
        var self = this;

        if(this.elements.navSearchInput) {

            this.elements.navToggle.on('click', function(event){
                if (self.isOpen) {
                    event.preventDefault();     // Don't focus on close
                    self.setCurrentTab(0);      // Select tab 0
                }
                self.activeFilters = { type: false, name: false }   // Reset filters
                self.filterValues = { type: '', name: '' };         // Reset filters
                self.filter();
                self.toggle();
            });

            this.elements.navSearchInput.on('focus', function(event) {
                self.open();
            })

            this.elements.navSearchInput.on('input', function(event){
                self.activeFilters = { type: false, name: (!!this.value) };
                self.filterValues.name = this.value.toUpperCase();
                self.filter();
            });
        }

        this.elements.navTabItems.on('click', function(event){
            event.preventDefault();
            var type = $(this).attr('data-type');
            self.setCurrentTab($(this).index());
            self.activeFilters = { type: (!!type), name: false };
            self.filterValues.type = type;
            self.filter();
            LazyLoad.loadAll(self.elements.list[0]);
        });

        var type = $('.active').attr('data-type');
        self.activeFilters = { type: (!!type), name: false };
        self.filterValues.type = type;

        this.filter();

    },

    setCurrentTab: function(index) {
        this.elements.navTabItems.removeClass('active');
        this.elements.navTabItems.eq(index).addClass('active');

        var url = $(this.elements.navTabItems.eq(index)).attr('href');
        if (url && url!='#') {
            history.pushState(null, null, url);
        }
    },

    filter: function() {
        var self = this;
        var noResults = true;

        this.elements.listItems.each(function(){

            var matchedFilters = {
                type: false,
                name: false
            }

            var matched = true;

            matchedFilters.type = self.filterByType($(this).find('.type').attr('data-type'));
            matchedFilters.name = self.filterByName($(this).find('h2').html());

            for (var key in self.activeFilters) {
                if (self.activeFilters[key] != matchedFilters[key]) {
                    matched = false;
                    break;
                }
            }

            if (matched) {
                noResults = false;
                $(this).removeClass('hide');
            } else {
                $(this).addClass('hide');
            }

        });

        if (noResults) {
            this.elements.list.addClass('no-results');
        } else {
            this.elements.list.removeClass('no-results');
        }
    },

    filterByType: function(type) {
        return (this.filterValues.type != '' && this.filterValues.type == type)
    },

    filterByName: function(name) {
        return (this.filterValues.name != '' && name.toUpperCase().indexOf(this.filterValues.name) > -1)
    },

    toggle: function() {
        if (this.isOpen) this.close();
        else this.open();
    },

    open: function() {
        this.isOpen = true;
        this.elements.navSearchInput.val('');
        this.elements.navContainer.addClass('search-active');
        if (! this.elements.navSearchInput.is(':focus')) {
            this.elements.navSearchInput.focus();
        }
    },

    close: function() {
        this.isOpen = false;
        this.elements.navContainer.removeClass('search-active');
        this.elements.navSearchInput.val('');
    }
}

export default InspirationSearchbar;
