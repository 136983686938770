// ==========================================================================
// Used for toggling aria states with accompanying classes
// ==========================================================================


class Toggle {
  constructor() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open(control, target, className) {
    if (control !== undefined) {
      control.classList.add(className);
      control.setAttribute('aria-expanded', 'true');
    }
    if (target !== undefined) {
      target.classList.add(className);
      target.setAttribute('aria-hidden', 'false');
    }
  }

  close(control, target, className) {
    if (control !== undefined) {
      control.classList.remove(className);
      control.setAttribute('aria-expanded', 'false');
    }
    if (target !== undefined) {
      target.classList.remove(className);
      target.setAttribute('aria-hidden', 'true');
    }
  }
}

export default new Toggle;


