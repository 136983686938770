function objectToPropertyDescriptor(reference, obj) {
    var object = obj || {};

    var propertyDescriptor = {
        constructor: {
            writable: true,
            enumerable: true,
            configurable: true,
            value: reference
        }
    };

    Object.keys(object).forEach(function(property) {
        propertyDescriptor[property] = {
            writeable: true,
            enumerable: false,
            configurable: true,
            value: object[property]
        };
    });

    return propertyDescriptor;
}

export default objectToPropertyDescriptor;
