// ==========================================================================
// Define modal callbacks here (initContent)
// ==========================================================================

import materialFields from './material-forms.js';
import { injectScript } from './helpers';
import mapStyles from './map-styles';
import modal from './modal.js';
import LazyLoad from './lazy-load';

export const callbacks = {
    flickityModal() {
        var modalCarousel = document.querySelector('.modal-packaging');

        if(modalCarousel.children.length>1) {
            var flkty = new Flickity(modalCarousel, {
                dots: false,
                wrapAround: true,
                cellSelector: '.modal-packaging-item',
                arrowShape: { x0: 30, x1: 60, y1: 30, x2: 65, y2: 25, x3: 40 },
                imagesLoaded: true
            });
        }
    },

    googleMapsModal() {
        var googleApiKey = document.querySelector('meta[name="gmap-key"').getAttribute('content');
        if (window.google === undefined) {
            injectScript('https://maps.googleapis.com/maps/api/js?key=' + googleApiKey).then(() => initMap());
        } else initMap();

        function initMap() {
            const mapDiv = (document.getElementById('map'));
            const center = { lat: parseFloat(mapDiv.getAttribute('data-lat')), lng: parseFloat(mapDiv.getAttribute('data-lng'))};
            const svg = `
                <svg xmlns="http://www.w3.org/2000/svg"
                width="34" height="49" viewBox="0 0 34 49" >
                <defs><linearGradient x1="0%" y1="100%" x2="69.785%" y2="24.308%" id="a"><stop offset="0%"/><stop stop-color="#D8D8D8" stop-opacity="0" offset="100%"/></linearGradient></defs><g transform="translate(0 .7)" fill="none" fill-rule="evenodd"><path fill="url(#a)" style="mix-blend-mode:multiply" opacity=".374" d="M17 48l17-7.528-9.042-5.91z"/><path d="M17 48c0 .123 17-22.208 17-31.385C34 7.44 26.389 0 17 0S0 7.439 0 16.615C0 25.792 17 47.875 17 48z" fill="#D71920"/><circle fill="#FFF" cx="17" cy="15" r="5"/></g>
                </svg>`

            const markerIcon = {
                url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
                anchor: new google.maps.Point(17, 48.7)
            }


            var map = new google.maps.Map(mapDiv, {
                zoom: 12,
                center: center,
                styles: mapStyles,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
            });

            var marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: markerIcon
            });
        };
    },

    youtubeModal() {
        var videoId = document.querySelector('#ytplayer').getAttribute('data-videoId');
        var player;

        if( !window.onYouTubeIframeAPIReady) {
            var tag = document.createElement('script');

            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            window.onYouTubeIframeAPIReady = function() {
                loadPlayer();
            };
        } else {
            loadPlayer();
        }

        function loadPlayer() {
            player = new YT.Player('ytplayer', {
                height: '650',
                width: '1156',
                videoId: videoId,
                playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    showinfo: 0
                }
            });
        }
    },

    formModal() {
        if (materialFields) materialFields.init('.modal-overlay');

        $('.modal-form').on('submit', function(e) {
            e.preventDefault();

            $("button[type=submit]", $(this)).attr('disabled', 'disabled');

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success == true) {
                        modal.closeModal('window');
                        bannerAlert();
                    } else {
                        refreshModalContent(data);
                        LazyLoad.loadAll(document.querySelector('.modal-content'));
                    }
                },
            });

            function bannerAlert() {
                var banner = document.querySelector('.fs-banner');
                banner.classList.add('open');
                setTimeout( function() {
                    banner.classList.remove('open');
                }, 3000);
            }

            function refreshModalContent(data) {
                modal.provideContent({
                    modalType: 'window',
                    contentData: data.html,
                    callback: callbacks['formModal'],
                });

            }
        })
    },

    redirectModal() {
        $('.js-modal-redirect').on('click', function(e) {
            e.preventDefault();
            modal.provideContent(modal.createOptions(this));
        });
    },

    newsletterModal() {
        $('.modal-overlay #ftr-nl').val($('#ftr-nl-display').val())
        if (materialFields) materialFields.init('.modal-overlay');

        $('.newsletter-modal-form').on('submit', function(e) {
            e.preventDefault();

            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        refreshModalContent(data);
                    } else {
                        bannerAlert();
                    }
                },
            });

            function refreshModalContent(data) {
                modal.provideContent({
                    modalType: 'window',
                    url: data.redirect_to,
                    callback: callbacks['formModal']
                });
            }

            function bannerAlert() {
                modal.closeModal('window');
                var banner = document.querySelector('.nlf-banner');
                banner.classList.add('open');
                setTimeout( function() {
                    banner.classList.remove('open');
                }, 3000);
            }
        });
    }
}
