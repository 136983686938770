class Submenus {
  constructor() {
    this.submenuControls = [...document.querySelectorAll('.js-submenu-mobile-control')];

    this.onSubmenuClick = this.onSubmenuClick.bind(this);
    this.addEventListeners();
  }

  addEventListeners() {
    this.submenuControls.forEach(control =>
      control.addEventListener('click', this.onSubmenuClick),
    );
  }

  onSubmenuClick(event) {
    event.preventDefault();
    const control = event.target;
    const target = document.getElementById(
      control.getAttribute('aria-controls'),
    );

    if (control.getAttribute('aria-expanded') === 'false') {
      this.expand(control, target);
    } else {
      this.collapse(control, target);
    }
  };

  expand(control, target) {
    target.setAttribute('aria-hidden', false);
    control.setAttribute('aria-expanded', true);
    target.style.height = target.scrollHeight + 'px';
  }

  collapse(control, target) {
    target.setAttribute('aria-hidden', true);
    control.setAttribute('aria-expanded', false);
    target.style.height = 0;
  }
}

export default new Submenus();