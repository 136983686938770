class LazyLoad {
    constructor() {
        this.columnWidth = 96;
        this.gutterWidth = 24;
        this.minCols = 0;
        this.maxWidth = 1440;
        this.loadAll(document.querySelector('body'));
    }

    snapNearest(value) {
        const colCalc = n =>
      n * (this.columnWidth + this.gutterWidth) / 4;
        let ncols = this.minCols;
        let nearest = 0;
        while (nearest < this.maxWidth) {
            nearest = colCalc(ncols++);
            if (value - nearest < colCalc(1) && value <= nearest) return nearest;
        }
        return this.maxWidth;
    }

    loadAll(container) {
        [...container.querySelectorAll('[data-src]')].map(image =>
      this.loadImage(image));

        const imgLoad = imagesLoaded(container);

        imgLoad.on('progress', (instance, image) => {
            const {width, height} = image.img.getBoundingClientRect();

            if (image.isLoaded && width !==0) image.img.classList.add('loaded');
        });
    }

    reloadImages(container) {
        var images = [...container.querySelectorAll('.loaded')];
        images.forEach((image) => {
            image.classList.remove('loaded');
        });
        this.loadAll(container);
    }

    loadImage(image) {
        const width = parseInt(image.getBoundingClientRect().width);
        const height = parseInt(image.getBoundingClientRect().height);
        let src;
        if (image.classList.contains('srcset')) {
            const srcset = (JSON.parse(image.dataset.src));
            if (window.matchMedia('(min-width: 768px)').matches) {
                src = srcset.desktop;
            } else {
                src = srcset.mobile;
            }
        } else {
            src = image.dataset.src;
        }
        const baseUrl = image.dataset.base;
        let quality = 'q_auto';
        let gravity = 'g_auto';

        if (image.dataset.gravity) {
            gravity = 'g_' + image.dataset.gravity
            if (image.dataset.gravity === 'xy_center' && image.dataset.gravity_center) {
                gravity = 'g_' + image.dataset.gravity + ',' + image.dataset.gravity_center;
            }
        }

        if (image.dataset.quality) {
            quality = image.dataset.quality;
        }

        if (image.classList.contains('loaded') || width == 0)
            return;

        if (image.tagName === 'IMG') {
            if (src == '' || src == 'vNone/None')
                return;

            let imgDimensions = `w_${this.snapNearest(width)},ar_${image.dataset.ratio}`;
            if (image.dataset.dimensions) {
                imgDimensions = image.dataset.dimensions
            }

            const imageParams = `${imgDimensions},${gravity},${quality},c_fill,f_auto`;
            const url = `${baseUrl}/${imageParams}${src}`;
            image.setAttribute('src', url);
            image.setAttribute('srcset', url);
        } else {
            // const imageParams = `w_${this.snapNearest(width)},h_${height > 1000 ? 1000 : 100 * Math.round(height / 100)},c_fill,g_auto,f_auto`;
            let bgDimensions = `w_${this.snapNearest(width)},h_${this.snapNearest(height)}`;
            if (image.dataset.dimensions) {
                bgDimensions = image.dataset.dimensions
            }

            const imageParams = `${bgDimensions},${gravity},${quality},c_fill,f_auto`;
            const url = `${baseUrl}/${imageParams}${src}`;
            image.style.backgroundImage = `url(${url})`;
            imagesLoaded(image, {background: true}, () =>
            image.classList.add('loaded'));
        }
    }
}

export default new LazyLoad();
