/**
* Used for detecting when an element is rendered
* @param {Node} element - The element that is waiting to be rendered
* @param {Function} callback - The function that fires after rendering
*/

export function onRender(element, callback) {
    element.getBoundingClientRect();
    requestAnimationFrame(() =>
        requestAnimationFrame(() =>
            callback()));
}

/**
* A shorthand for transitionEnd
* @param {Node} element - Element that is being transitioned
* @param {Function} callback - What to do afterwards
*/
export function onTransitionEnd(element, callback) {
    const transitionEndFn = () => {
        element.removeEventListener('transitionend', transitionEndFn);
        callback();
    };
    element.addEventListener('transitionend', transitionEndFn);
}

/**
* String to Document Fragment
* @param {string} html - html string
* @returns {Node} fragment - the returned document fragment
*/

export function htmlToFragment(html) {
    const proxy = document.createElement('div');
    proxy.innerHTML = html;
    const fragment = document.createDocumentFragment();
    while (proxy.firstChild)
        fragment.appendChild(proxy.firstChild);
    return fragment;
}


/**
* Default fetch options. If there is a body - it's a POST
* @param {Object} body
* @returns {Object}
*/

export function fetchOptions(body) {
    const csrfToken = document.querySelector('meta[name="csrf"]').getAttribute('content');

    const headers = new Headers({
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRFToken': csrfToken
    });

    const options = {
        credentials: 'include',
        headers: headers
    };

    if (typeof body !== 'undefined') {
        options.method = 'POST';

        if (typeof body === 'object') {
            options.body = JSON.stringify(body);
            headers.append('Content-Type', 'application/json');
        }
    }

    return options;
}

/**
* Handles errors for fetch requests
* @param {Object} response
*/

export function handleErrors(response) {
    if (!response.ok) throw Error(response.statusText);
    return response;
}

/**
* Checks if an event is a left click
* @param {Event} event
* @returns {Boolean}
*/

export function isLeftClick(event) {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) return 1;
    else return 0;
}


/**
* Data loader - optional body turns it into a POST
* @param {String} url
* @param {Object} [body]
* @returns {String}
*/

export function loadData(url, body) {
    return fetch(url, fetchOptions(body))
    .then(handleErrors)
    .then(response => {
        if (response.headers.get('Content-Type') === 'application/json')
            return response.json();
        else if (response.headers.get('Content-Type').includes('text/html'))
            return response.text();
        else
            return 'Something went wrong. Check out the log.';
    });
}

/**
* Async script load
* @export
* @param {URL} url
*/
export function injectScript(url) {
    return new Promise((resolve, reject) => {
        const js = document.createElement('script');
        js.src = url;
        js.defer = 'true';
        js.async = 'true';
        js.onload = resolve;
        js.onerror = reject;
        document.head.appendChild(js);
    });
}



/**
* Object Extend (Shallow)
*
* @param {Object} Output
* @param {Object} Object A
* ...
* @param {Object} Object N
*
* e.g.
* extend({}, objA, objB);
* extend(defaults, options);
* extend(obj, objA, objB, objC, objD);
*/
export function extend(out) {
    out = out || {};

    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i]) continue;

        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key)) {
                out[key] = arguments[i][key];
            }
        }
    }

    return out;
};


/**
* Object Extend (Deep)
*
* @param {Object} Output
* @param {Object} Object A
* ...
* @param {Object} Object N
*
* e.g.
* deepExtend({}, objA, objB);
* deepExtend(defaults, options);
* deepExtend(obj, objA, objB, objC, objD);
*/
export function deepExtend(out) {
    out = out || {};

    for (var i = 1; i < arguments.length; i++) {
        if (!arguments[i]) continue;

        for (var key in arguments[i]) {
            if (arguments[i].hasOwnProperty(key)) {
                if (typeof arguments[i][key] === 'object') {
                    out[key] = deepExtend(out[key], arguments[i][key]);
                } else {
                    out[key] = arguments[i][key];
                }
            }
        }
    }

    return out;
};


/**
* Interface Observable
*
* Mixin for observer pattern interface. Extend objects prototype.
*/
export var Observable = {

    addListener: function(listenerObj) {
        if (typeof this.listeners === 'undefined') {
            this.listeners = [];
        }
        for (var i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i] == listenerObj) return;
        }
        this.listeners[this.listeners.length] = listenerObj;
    },

    removeListener: function(listenerObj) {
        for (var i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i] == listenerObj) {
                this.listeners.splice(i, 1);
                return;
            }
        }
    },

    notifyListeners: function(eventName, eventObj) {
        if (typeof this.listeners === 'undefined') return;
        for (var i = 0; i < this.listeners.length; i++) {
            if (typeof this.listeners[i][eventName] !== 'undefined') {
                this.listeners[i][eventName](eventObj);
            }
        }
    }
};
