// ==========================================================================
// Modal controller / singleton
// ==========================================================================

import toggle from './aria-toggle';
// import scrollbarCompensate from './scrollbar-compensate';
import { onRender, loadData, onTransitionEnd, isLeftClick } from './helpers';
import { callbacks } from './modal-callbacks';
import LazyLoad from './lazy-load';

class Modal {
  /**
   * Creates an instance of Modal.
   */
    constructor() {
        this.dom = {
            html: document.querySelector('html'),
            window: {
                overlay: document.querySelector('.js-modal-overlay'),
                window: document.querySelector('.js-modal-window'),
                closeBtn: document.querySelector('.js-modal-close'),
                content: document.querySelector('.js-modal-content')
            },
            fullscreen: {
                overlay: document.querySelector('.js-modal-overlay-fs'),
                window: document.querySelector('.js-modal-window-fs'),
                closeBtn: document.querySelector('.js-modal-close-fs'),
                content: document.querySelector('.js-modal-content-fs')
            }
        };

    /** Public methods */
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.bindModals();
    }

  /**
   * Adds event handlers for opening and closing modals
   */
    bindModals() {
        this.bindOpenModal();
        this.bindCloseModal('fullscreen');
        this.bindCloseModal('window');
    }

  /**
   * Adds event handlers for opening modals
   */
    bindOpenModal() {
        this.controls = [...document.querySelectorAll('.js-modal-open')];
        this.controls.forEach((control) => {
            control.addEventListener('click', (event) => {
                event.preventDefault();
                if(!control.classList.contains('is-disabled')) {
                    this.openModal(this.createOptions(control));
                }
            });
        });
    }

  /**
   * @param {HTMLElement} control - the DOM element that called the modal
   * @returns {Object}
   */
    createOptions(control) {
        return {
            modalType: control.getAttribute('data-type'),
            url: control.getAttribute('data-url'),
            contentNode: document.querySelector('#' + control.getAttribute('data-dom-content')),
            callback: callbacks[control.getAttribute('data-callback')]
        };
    }

  /**
   * Adds event handlers for closing modals
   * @param {String} modalType
   */
    bindCloseModal(modalType) {
        this.dom[modalType].closeBtn.addEventListener('click', (event) => {
            event.preventDefault();
            this.closeModal([modalType]);
        });

        this.dom.window.window.addEventListener('click', (event) => {
            event.stopPropagation();
        });

        this.dom.window.overlay.addEventListener('click', (event) => {
            if (isLeftClick(event)) {
                this.closeModal('window');
            }
        });

        window.addEventListener('keyup', (event) => {
            if (event.keyCode == 27) {
                event.preventDefault();
                this.closeModal('window');
            }
        });

    }

  /**
   * Opens a modal
   * @param {Object} options
   */
    openModal(options) {
        this.provideContent(options);
        toggle.open(undefined, this.dom[options.modalType].overlay, undefined);
        this.dom[options.modalType].overlay.classList.add('modal-rendered'); // display: block na modal

        onRender(this.dom[options.modalType].overlay, () => {
            onTransitionEnd(this.dom[options.modalType].overlay, () => {
                this.dom[options.modalType].overlay.classList.add('modal-interactable'); // pointer-events: all;
                if (options.modalType === 'window') {
                    this.dom[options.modalType].overlay.focus();
                }
                LazyLoad.loadAll(this.dom[options.modalType].overlay);
            });

            this.dom.html.classList.add('no-scroll'); // overflow: hidden na body
            this.dom[options.modalType].overlay.classList.add('modal-visible'); // opacity: 1 na modal
    //   scrollbarCompensate.compensate(); // add margin if needed


        });
    }

  /**
   * Provides content
   * @param {Object} options
   */
    provideContent(options) {
        if (typeof options.url === 'string' && options.url !== '') {
            loadData(options.url)
        .then((data) => {
            var html;
            if (typeof(data) == 'string') {
                html = data;
            } else if (typeof(data) == 'object') {
                html = data.html;
            }
            this.addContent(html, options.modalType);
            return data;
        })
        .then((json) => {
            if (typeof options.callback === 'function') {
                requestAnimationFrame(() => this.dom[options.modalType].content, options.callback(json));
            }
        });
        } else if (typeof options.contentNode === 'object') {
            this.addContent(options.contentNode.innerHTML, options.modalType);
            if (typeof options.callback === 'function') {
                requestAnimationFrame(() => this.dom[options.modalType].content, options.callback());
            }
        } else if (options.contentData !== '') {
            var html;
            if (typeof(options.contentData) == 'string') {
                html = options.contentData;
            } else if (typeof(options.contentData) == 'object') {
                html = options.contentData.html;
            }
            this.addContent(html, options.modalType);
            if (typeof options.callback === 'function') {
                requestAnimationFrame(() => this.dom[options.modalType].content, options.callback());
            }
        }
    }

  /**
   * Adds content to the DOM
   * @param {String} htmlString
   */
    addContent(htmlString, modalType) {
        this.dom[modalType].content.innerHTML = htmlString;
    }

  /**
   * Removes content from the DOM
   * @param {String} modalType
   */
    removeContent(modalType) {
        this.dom[modalType].content.innerHTML = null;
    }

  /**
   * Closes a modal
   * @param {String} modalType
   * @param {String} url
   */
    closeModal(modalType) {
        onTransitionEnd(this.dom[modalType].overlay, () => {
            this.dom[modalType].overlay.classList.remove('modal-rendered');
            this.removeContent(modalType);
            requestAnimationFrame(() => {
                this.dom.html.classList.remove('no-scroll');
        // scrollbarCompensate.reset();
            });
        });

        this.dom[modalType].overlay.classList.remove('modal-visible');
        this.dom[modalType].overlay.classList.remove('modal-interactable');
        toggle.close(undefined, this.dom[modalType].overlay, undefined);
    }
}

/** Export a constructed singleton */
export default new Modal;
