function Navigation() {
    this.sOpen = false;
    this.$header = $('header');
    this.$primary = $('.header-primary');
    this.$secondary = $('.header-secondary');
    this.init();
}

Navigation.prototype = {

    init: function() {
        this.bindEvents();
    },

    secondaryToggle: function() {
        if (this.sOpen) this.secondaryClose();
        else this.secondaryOpen();
    },

    secondaryOpen: function() {
        this.sOpen = true;
        this.$header.addClass('secondary-open');
        $('body').addClass('no-scroll');
    },

    secondaryClose: function() {
        this.sOpen = false;
        this.$header.removeClass('secondary-open');
        $('body').removeClass('no-scroll');
    },

    bindEvents: function() {
        var self = this;
        $('.nav-item.has-children', self.$primary).on('mouseenter', function(){
            self.$header.addClass('primary-open');
        });
        $('.nav-item.has-children', self.$primary).on('mouseleave', function(){
            self.$header.removeClass('primary-open');
        });

        // close on outside click without stopPropagation
        $(document).on('click', function(event) {
            if (!self.$secondary.is(event.target) && self.$secondary.has(event.target).length === 0) {
                self.secondaryClose();
            }
        });

        $('.js-secondaryToggle').on('click', function(event){
            event.stopPropagation();
            event.preventDefault();
            self.secondaryToggle();
        });
    }
}

export default new Navigation;
