function LangSwitcher(element) {
    this.isOpen = false;
    this.element = element;
    this.init();
}

LangSwitcher.prototype = {

    init: function() {
        this.bindEvents();
    },

    toggle: function() {
        if (this.isOpen) this.close();
        else this.open();
    },

    open: function() {
        this.isOpen = true;
        this.element.addClass('open');
    },

    close: function() {
        this.isOpen = false;
        this.element.removeClass('open');
    },

    bindEvents: function() {
        var self = this;

        // close on outside click without stopPropagation
        $(document).on('click', function(event) {
            if (!self.element.is(event.target) && self.element.has(event.target).length === 0) {
                self.close();
            }
        });

        this.element.on('click', function(event){
            event.stopPropagation();
        });

        this.element.find('button').on('click', function(){
            self.toggle();
        });

    }
}

export default LangSwitcher;
