import debounce from 'lodash.debounce';

class HistorySlideshow {

    constructor() {
        this.slideshowThumb = document.querySelector('#slideshow-dot');
        if (!this.slideshowThumb) return;

        this.slideshow = document.querySelector('.history-slideshow-content');
        this.maxScrollLeft = this.slideshow.scrollWidth - this.slideshow.clientWidth;
        this.mouseXPos = null;
        this.mouseDown = false;
        this.slideshowPosition = null;

        this.drag = this.drag.bind(this);
        this.scroll = this.scroll.bind(this);
        this.range = this.range.bind(this);

        this.addEventListeners();
    }

    addEventListeners() {
        this.slideshowThumb.addEventListener('input', this.range);
        this.slideshowThumb.addEventListener('change', this.range);
        this.slideshow.addEventListener('mousedown', this.drag);
        this.slideshow.addEventListener('scroll', this.scroll);
        window.addEventListener('resize', debounce(function() {
            this.maxScrollLeft = this.slideshow.scrollWidth - this.slideshow.clientWidth;
        }.bind(this), 400));
    }

    range(e) {
        requestAnimationFrame(function() {
            e.preventDefault;
            this.slideshow.scrollLeft = (this.slideshowThumb.value* this.maxScrollLeft)/100;
        }.bind(this));
    }

    drag(e) {
        this.mouseDown = true;
        this.mouseXPos = e.clientX;
        this.slideshowPosition = this.slideshow.scrollLeft;
        this.slideshow.classList.add('moving');

        var self = this;

        window.addEventListener('mousemove', function(e) {
            requestAnimationFrame(function() {
                if (self.mouseDown) {
                    self.slideshow.scrollLeft = self.slideshowPosition + (self.mouseXPos - e.clientX);
                };
            });
        });

        window.addEventListener('mouseup', function() {
            self.mouseDown = false;
            self.slideshow.classList.remove('moving');
        });
    }

    scroll() {
        requestAnimationFrame(function() {
            this.slideshowThumb.value = this.slideshow.scrollLeft * 100 / this.maxScrollLeft;
            if (this.slideshowThumb.value != 0) {
                this.slideshowThumb.parentNode.classList.remove('display-msg');
            } else {
                this.slideshowThumb.parentNode.classList.add('display-msg');
            }
        }.bind(this));
    }
}

export default new HistorySlideshow;



