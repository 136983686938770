import objectToPropertyDescriptor from './object-to-property-descriptor.js';

/* --------------------------------------------------------------------------------------------------------------- */
/* Material Forms
/* --------------------------------------------------------------------------------------------------------------- */

function MaterialFields() {
    // Two arrays are for indexOf usage (indexOf is 2x faster than a regular for loop)
    // Object node can't be a dictionary key so this was my way to have the domObject reference
    // the newly created object (arrays share indexes)
    this.objectList = [];
    this.elementList = [];

    this.mappedFields = {
        '.field-input': FieldInput,
        '.field-text': FieldInput,
        '.field-email': FieldInput,
        '.field-number': FieldInput,
        '.field-select': FieldSelect,
        '.field-textarea': FieldTextarea
    }
    this.globalSelectEvents();
}



MaterialFields.prototype = {
    init: function(context) {
        var self = this;
        var ctx = context || 'body';
        for (var key in this.mappedFields) {
            $(key, ctx).each(function() {
                if (self.elementList.indexOf(this) >= 0) return true;
                this.setAttribute('mf-init', 'true')
                self.elementList.push(this);
                self.objectList.push(new self.mappedFields[key]($(this), self));
            });
        }
        // console.log(this.objectList)
        // console.log(this.elementList)
    },

    uninit: function(context) {
        var self = this;
        var ctx = context || 'body';
        for (var key in this.mappedFields) {
            $(key, ctx).each(function() {
                var index = self.elementList.indexOf(this);
                if (index < 0) return true; // Prevent multiple uninits
                self.elementList[index].removeAttribute('mf-init');
                self.elementList.splice(index,1);
                self.objectList[index].uninit();
                self.objectList.splice(index,1);
            });
        }
        // console.log(this.objectList)
        // console.log(this.elementList)
    },

    validate: function(context) {
        var self = this;
        var ctx = context || this.context;
        for (var key in this.mappedFields) {
            $(key, ctx).each(function() {
                var index = self.elementList.indexOf(this);
                self.objectList[index].validate();
            });
        }
    },

    getAllSelect: function(exception) {
        var list = [];
        for (var key in this.objectList) {
            if (this.objectList[key].type == 'FieldSelect' && exception != this.objectList[key]) {
                list.push(this.objectList[key]);
            }
        }
        return list;
    },

    globalSelectEvents: function() {
        var self = this;

        $('body').on('click.MFs', function(event) {
            var selectList = self.getAllSelect();
            for (var i = selectList.length - 1; i >= 0; i--) {
                selectList[i].setInactive();
            }
        });

        $('.js-modal-window').on('click.MFs', function(event) {
            var selectList = self.getAllSelect();
            for (var i = selectList.length - 1; i >= 0; i--) {
                selectList[i].setInactive();
            }
        });
    }
}



/* --------------------------------------------------------------------------------------------------------------- */
/* Field superclass
/* --------------------------------------------------------------------------------------------------------------- */

function Field(jqueryElement, model) {
    this.field = jqueryElement;
    this.model = model;
    this.type = 'Field';
    this.active = false;
}

Field.prototype = {

    setActive: function() {
        if (this.active) return;
        this.active = true;
        this.field.addClass('active');
    },

    setInactive: function() {
        if (!this.active) return;
        this.active = false;
        this.field.removeClass('active');
    },

    toggleActive: function() {
        if (this.active) this.setInactive();
        else this.setActive();
    },

    setValid: function() {
        this.field.addClass('valid');
    },

    setInvalid: function() {
        this.field.removeClass('valid');
    },


    // Child specific methods
    init: function() {},
    uninit: function() {},
    validate: function() {}

}



/* --------------------------------------------------------------------------------------------------------------- */
/* Input
/* - extends the generic field superclass
/* --------------------------------------------------------------------------------------------------------------- */

function FieldInput(jqueryElement, model) {
    Field.call(this, jqueryElement, model);
    this.type = 'FieldInput';
    this.elem = 'input';
    this.checkActive();
    this.validate();
    this.init();
}

FieldInput.prototype = Object.create(Field.prototype, objectToPropertyDescriptor(FieldInput, {

    init: function() {
        $(this.field).find(this.elem).on('focus.MFs change.MFs blur.MFs input.MFs', function(event) {
            event.stopPropagation();
            this.checkActive();
            this.validate();
        }.bind(this));
    },

    uninit: function() {
        this.setInvalid();
        this.setInactive();
        $(this.field).find(this.elem).off('focus.MFs blur.MFs change.MFs input.MFs');
    },

    checkActive: function() {
        // find method is faster than the context selector
        var item = this.field.find(this.elem);

        // Check focus
        if (item.is(":focus")) {
            this.setActive();
        } else if (item.val() && item.val().length > 0) {
            this.setActive();
        } else {
            this.setInactive();
        }
    },

    validate: function() {
        // find method is faster than the context selector
        var item = this.field.find(this.elem);

        // Check value
        if (item.val() && item.val().length > 0) {
            this.setValid();
        }
        else {
            this.setInvalid();
        }
    }
}));




/* --------------------------------------------------------------------------------------------------------------- */
/* Textarea
/* - extends field superclass but inherits methods from the fieldInput class
/* --------------------------------------------------------------------------------------------------------------- */

function FieldTextarea(jqueryElement, model) {
    Field.call(this, jqueryElement, model);
    this.type = 'FieldTextarea';
    this.elem = 'textarea';
    this.checkActive();
    this.validate();
    this.init();
}

FieldTextarea.prototype = Object.create(FieldInput.prototype, objectToPropertyDescriptor(FieldTextarea));



/* --------------------------------------------------------------------------------------------------------------- */
/* Select
/* - extends the generic field superclass
/* --------------------------------------------------------------------------------------------------------------- */

function FieldSelect(jqueryElement, model) {
    Field.call(this, jqueryElement, model);
    this.type = 'FieldSelect';
    this.elem = 'button';
    this.item = 'li';
    this.buidMarkup();
    this.validate();
    this.init();
}

FieldSelect.prototype = Object.create(Field.prototype, objectToPropertyDescriptor(FieldSelect, {

    buidMarkup: function() {
        var $select = $(this.field).find('select');
        var initialText = $select.first('option').html();

        var button = $('<button />').attr('type', 'button').attr('tabindex', '-1').html(initialText);
        var ul = $('<ul />');

        if ($select.hasClass('upward')) {
            ul.addClass('upward');
        }

        $select.find('option').each(function(index){
            var classes = [];

            if ($(this).is('[disabled]')) {
                classes.push('disabled');
            }

            if ($(this).is(':selected')) {
                button.html($(this).html());
                classes.push('selected');
            }

            var li = $('<li />').html($(this).html())
                                .attr('data-value', $(this).attr('value'))
                                .attr('title', $(this).attr('title'))
                                .addClass(classes.join(" "))
            ul.append( li );
        });

        $select.before( button, ul );
    },

    removeMarkup: function() {
        $(this.field).find('button, ul').remove();
    },

    setCurrent: function(selectItem) {
        // Do nothing if the field is disabled or selected
        if (selectItem.hasClass('disabled') || selectItem.hasClass('selected')) return;

        // Unselect all and then select this item
        $(this.field).find(this.item).removeClass('selected');
        selectItem.addClass('selected');

        // Get values
        var value = selectItem.attr('data-value');
        var text = selectItem.html();

        // Set values
        $(this.field).find('select').val(value);
        $(this.field).find(this.elem).html(text);
    },

    setCurrentFallback: function(selectItem) {
        var value = selectItem.val();
        var $selected = $(this.field).find('option[value="'+value+'"]');
        var $listItem = $(this.field).find('li[data-value="'+value+'"]');

        $(this.field).find('li').removeClass('selected');
        $listItem.addClass('selected');

        $(this.field).find(this.elem).html($selected.text());
    },

    validate: function() {
        // Check if field has a selected value
        if ($(this.field).find('select').val()) {
            this.setValid();
        } else {
            this.setInvalid();
        }
    },

    init: function() {
        var self = this;

        // Fallback for visually impaired
        $(this.field).find('select').on('change.MFs', function() {
            self.setCurrentFallback($(this));
        });

        // Toggle select dropdown
        $(this.field).find(this.elem).on('click.MFs', function() {
            var selectList = self.model.getAllSelect(self);
            for (var i = selectList.length - 1; i >= 0; i--) {
                selectList[i].setInactive();
            }
            self.toggleActive();
        });

        // Actually select an item
        $(this.field).find(this.item).on('click.MFs', function() {
            self.setCurrent($(this));
            self.setInactive();
            self.validate();
        });

        $(this.field).on('click.MFs', function(event) {
            event.stopPropagation();
        });

    },

    uninit: function() {
        this.setInvalid();
        this.setInactive();
        $(this.field).find(this.elem).off('click.MFs');
        $(this.field).find(this.item).off('click.MFs');
        $(this.field).off('click.MFs');
        this.removeMarkup();
    }

}));

var materialFields = new MaterialFields();

/* --------------------------------------------------------------------------------------------------------------- */
/* Export module
/* --------------------------------------------------------------------------------------------------------------- */

export default materialFields;
