var animatedScrollTo = {
    scrolling: false,

    // easeInOutQuad
    ease: function (t, b, c, d) {
        t /= d/2;
        if (t < 1) {
            return c/2*t*t + b;
        }
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    },

    moveScrollbar: function(position) {
        document.documentElement.scrollTop = position;
        document.body.parentNode.scrollTop = position;
        document.body.scrollTop = position;
    },

    currentPosition: function() {
        return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
    },

    disableHover: function(toggle) {
        if (!!toggle) {
            $('html').addClass('hover-disabled');
        } else {
            $('html').removeClass('hover-disabled');
        }
    },

    scrollTo: function(options) {
        if (this.scrolling) return;
        if (isNaN(options.position)) return;

        var self = this;

        var position = options.position;
        var duration = options.duration || 500;
        var callback = options.callback || false;
        var offset = options.offset || 0;

        var origin = self.currentPosition();
        var distance = position - origin - offset;
        var currentTime = 0;
        var increment = 20;

        self.scrolling = true;

        function animateScroll() {
            // disable hover effects on scroll animation
            self.disableHover(true);

            // increment the time
            currentTime += increment;

            // find the value with the quadratic in-out easing function
            var value = self.ease(currentTime, origin, distance, duration);

            // move the document.body
            self.moveScrollbar(value);

            // do the animation unless its over
            if (currentTime < duration) {

                requestAnimationFrame(animateScroll);

            } else {

                // enable scroll
                self.disableHover(false);
                self.scrolling = false;

                // just in case
                cancelAnimationFrame(animateScroll);

                // callback
                if (typeof callback === 'function') {
                    callback();
                }
            }
        };

        animateScroll();
    }
}

export default animatedScrollTo;
