// Polyfills
// import requestAnimationFrame from './polyfill/raf.js';
import 'core-js/modules/es6.map';
import 'core-js/modules/es6.set';
import 'core-js/modules/es6.array.from';
import 'core-js/modules/es6.array.for-each';
import 'core-js/modules/es6.array.iterator';
import 'core-js/modules/es6.string.includes';
import 'core-js/modules/es6.object.assign';
import 'core-js/modules/es6.promise';
import 'core-js/modules/web.dom.iterable';
import 'custom-event-polyfill';
import 'whatwg-fetch';

// Vendor
import debounce from 'lodash.debounce';

// Modules
import { loadData, htmlToFragment, onTransitionEnd } from './modules/helpers';
import { callbacks } from './modules/modal-callbacks';
import LazyLoad from './modules/lazy-load.js';
import Navigation from './modules/navigation.js';
import Submenus from './modules/mobileSubmenus.js';
import materialFields from './modules/material-forms.js';
import LanguageSwitcher from './modules/language-switcher.js';
import animatedScrollTo from './modules/animated-scroll-to.js';
import InspirationSearchbar from './modules/inspiration-searchbar.js';
import modal from './modules/modal.js';
import Accordion from './modules/accordion.js';
import HistorySlideshow from './modules/history-slideshow.js';


// Init global modal
window.Franck_modal = modal;

// Init material forms
materialFields.init();

// For testing purposes only
$('.js-init').on('click', function() {
    materialFields.init();
});

$('.js-destroy').on('click', function() {
    materialFields.uninit('.form-gutters');
});

// Truncate long text
if (document.querySelector('body').classList.contains('retail')) {
    [...document.querySelectorAll('.home-article-text h2')].forEach(function(article) {
        const text = article.textContent;
        if (window.matchMedia('(min-width: 480px)').matches) {
            shave(article, 125)
        }
        window.addEventListener('mediaChange', function() {
            article.textContent = text;
            if (window.matchMedia('(min-width: 480px)').matches) {
                shave(article, 125)
            }
        });
    });
};

// Create language switchers
$('.js-langSwitcher').each(function() {
    new LanguageSwitcher($(this));
});


// Initiate inspiration searchbar
var inspirationSearchbar = new InspirationSearchbar({
    list: $('.inspiration-list'),
    listItems: $('.inspiration-list-item'),
    navContainer: $('.inspiration-nav'),
    navToggle: $('.inspiration-nav-toggle label'),
    navSearchInput: $('.inspiration-nav-search input'),
    navTabItems: $('.inspiration-nav-tabs a'),
});

$(document).ready(() => {
  var filtersList = $('.inspiration-nav-tabs .inner').children();
  var urlHash = window.location.hash.slice(1);

  if (urlHash) {
    filtersList.each(function(key, element) {
      if (parseInt(urlHash, 10) && parseInt(urlHash, 10) === parseInt($(element).data('type'), 10)) {
        $(element).trigger('click');
      }
    })
  }
});

// B2B Products Filter
var productsB2BFilter = new InspirationSearchbar({
    list: $('.products-b2b-list .item-detailed-list'),
    listItems: $('.products-b2b-list .item-detailed-list-item'),
    navContainer: $('.filter-nav-b2b'),
    navTabItems: $('.filter-nav-b2b a'),
});

// Horeca Machines Filter
var machinesHorecaFilter = new InspirationSearchbar({
    list: $('.js-machine-list'),
    listItems: $('.js-machine-list-item'),
    navContainer: $('.filter-nav-machines'),
    navTabItems: $('.filter-nav-machines a'),
})

// Media Gallery
$('.media-gallery').each(function() {

    $(this).find('.media-gallery-items').flickity({
        pageDots: false,
        arrowShape: { x0: 30, x1: 60, y1: 30, x2: 65, y2: 25, x3: 40 },
        imagesLoaded: true
    });

    $(this).find('.media-gallery-thumbs').flickity({
        asNavFor: $(this).find('.media-gallery-items')[0],
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        imagesLoaded: true
    });

    LazyLoad.loadAll(this);
});

// Showcase carousel
[...document.querySelectorAll('.showcase-carousel')].forEach(function(carousel) {
    var showcaseCarousel = carousel.querySelector('.showcase-carousel-items');

    if (showcaseCarousel.children.length>1) {
        var flkty = new Flickity(showcaseCarousel, {
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            autoPlay: true,
        });
        LazyLoad.loadAll(carousel);
    };
});

// Campaign carousel
[...document.querySelectorAll('.campaign-carousel')].forEach(function(carousel) {
    var campaignCarousel = carousel.querySelector('.campaign-carousel-items');
    var flkty;

    function createCarousel() {
        flkty = new Flickity(campaignCarousel, {
            imagesLoaded: true,
            prevNextButtons: false,
            wrapAround: true,
            cellSelector: '.campaign-carousel-item',
        });
    }

    if(campaignCarousel.children.length>1) {
        createCarousel();
        LazyLoad.loadAll(carousel);

        window.addEventListener('mediaChange', function() {
            if (windowWidth < window.innerWidth) {
                flkty.destroy();
                createCarousel();
            }
        });
    }
});

// Campaign products
[...document.querySelectorAll('.campaign-products')].forEach(function(carousel) {
    var flkty;
    checkCarousel();

    window.addEventListener('mediaChange', function() {
        checkCarousel();
        LazyLoad.reloadImages(carousel);
    });

    function checkCarousel() {
        var campaignCarousel = carousel.querySelector('.campaign-products-items');
        var campaignCarouselItems = campaignCarousel.querySelectorAll('.campaign-products-item');

        if ((campaignCarouselItems.length>3) ||
            ((window.matchMedia('(max-width: 767px)').matches) && (campaignCarouselItems.length>2)) ||
            ((window.matchMedia('(max-width: 479px)').matches) && (campaignCarouselItems.length>1))) {
            if ((flkty == undefined) || !(flkty.element.classList.contains('flickity-enabled'))) {
                initCarousel();
            }
        } else if (flkty != undefined) {
            flkty.destroy();
        }

        function initCarousel() {
            flkty = new Flickity(campaignCarousel, {
                pageDots: false,
                wrapAround: true,
                cellSelector: '.campaign-products-item',
                cellAlign: 'left',
                arrowShape: { x0: 30, x1: 60, y1: 30, x2: 65, y2: 25, x3: 40 },
                imagesLoaded: true
            });
        }

    }
});

// News slideshow
[...document.querySelectorAll('.news-slideshow')].forEach(function(newsSlideshow) {
    var flkty;
    var slideshow = newsSlideshow.querySelector('.news-slideshow-items');
    checkCarousel();

    window.addEventListener('mediaChange', function() {
        checkCarousel();
    });

    function checkCarousel() {
        var newsSlideshowItems = slideshow.querySelectorAll('.news-slideshow-item');
        if ((window.matchMedia('(min-width: 768px)').matches) && (newsSlideshowItems.length>4)) {
            if ((flkty == undefined) || !(flkty.element.classList.contains('flickity-enabled'))) {
                flkty = new Flickity(slideshow, {
                    prevNextButtons: false,
                    pageDots: false,
                    imagesLoaded: true,
                    contain: true,
                    percentPosition: false,
                    freeScroll: true,
                });
            }
        } else {
            if (flkty != undefined) {
                flkty.destroy();
            }
            LazyLoad.reloadImages(slideshow);
        }
    }
});

// Recipe slideshow
[...document.querySelectorAll('.recipe-slideshow')].forEach(function(recipeSlideshow) {
    [...recipeSlideshow.querySelectorAll('.recipe-slideshow-items')].forEach(function(slideshow) {
        var flkty;
        checkCarousel();

        window.addEventListener('mediaChange', function() {
            checkCarousel();
        });

        function checkCarousel() {
            var recipeSlideshowItems = slideshow.querySelectorAll('.recipe-step');

            if (((window.matchMedia('(min-width: 1024px)').matches) && (recipeSlideshowItems.length>4)) ||
                ((window.matchMedia('(min-width: 768px) and (max-width: 1023px').matches) && (recipeSlideshowItems.length>3))) {
                if ((flkty == undefined) || !(flkty.element.classList.contains('flickity-enabled'))) {
                    flkty = new Flickity(slideshow, {
                        prevNextButtons: false,
                        pageDots: false,
                        imagesLoaded: true,
                        contain: true,
                        percentPosition: false,
                        freeScroll: true,
                    });
                    LazyLoad.reloadImages(slideshow);
                }
            } else {
                if (flkty != undefined) {
                    flkty.destroy();
                }
            }
        }
    });
});

// Business carousel
[...document.querySelectorAll('.business-carousel')].forEach(function(carousel) {
    var businessCarousel = carousel.querySelector('.business-carousel-items');

    window.addEventListener('mediaChange', function() {
        LazyLoad.reloadImages(carousel);
    });

    if (businessCarousel.children.length>1) {
        var flkty = new Flickity(businessCarousel, {
            wrapAround: true,
            imagesLoaded: true,
            prevNextButtons: false,
            autoPlay: true,
            setGallerySize: false
        });
        LazyLoad.loadAll(carousel);
    }
});

// Homepage popup
if (document.querySelector('body').classList.contains('popup')) {
    var promotionDiv = document.getElementById('promotion');
    var promotionName = promotionDiv.dataset.promotion;
    var promotionSeen = getCookie(promotion.dataset.promotion) || 0;
    var promotionSession = getCookie(promotion.dataset.promotion + '_session');

    if ((promotionSeen < 3) && !promotionSession) {
        $( document ).ready( function () {
            window.Franck_modal.openModal({
                modalType: 'window',
                contentNode: promotionDiv,
            });
        });

        setCookie(promotionName, parseInt(promotionSeen) + 1, ((10*365)+2));
        document.cookie = promotionName + "_session" + "=" + true;
    }


    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
}

// Form popup
if (window.location.search) {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('show_form')) {
        let formUrl = "";
        var currentLanguage = document.querySelectorAll('input[name=language]')[0].value;

        switch (urlParams.get('show_form')) {
            case "inquiry":
                formUrl = `/${currentLanguage}/core/form_category/1/`;
                break;
            case "press_inquiry":
                formUrl = `/${currentLanguage}/core/form_category/2/`;
                break;
            case "product_complaint":
                formUrl = `/${currentLanguage}/core/form_category/3/`;
                break;
            case "machine_complaint":
                formUrl = `/${currentLanguage}/core/form_category/7/`;
                break;
            case "praise":
                formUrl = `/${currentLanguage}/core/form_category/4/`;
                break;
            case "suggestion":
                formUrl = `/${currentLanguage}/core/form_category/5/`;
                break;
            case "partner":
                formUrl = `/${currentLanguage}/core/form_category/6/`;
                break;
            default:
                formUrl = "";
        }

        if (formUrl) {
            modal.openModal({
                modalType: 'window',
                url: formUrl,
                contentNode: null,
                callback: callbacks['formModal']
            });
        }
    }


}

// Homepage - hero-height
// $('.homepage-hero .hero-inner').css("height", window.innerHeight - $('header').height());

// window.addEventListener('orientationchange', function() {
//     $('.homepage-hero .hero-inner').css("height", window.innerHeight - $('header').height());
// })

// Homepage - hero-scroll
$('.hero-scroll').on('click', function(event) {
    event.preventDefault();

    var divID = $(this).parent();
    var headerHeight = 78;
    if (window.matchMedia('(max-width: 479px)').matches) {
        headerHeight = 67;
    }

    if( $(divID).length ) {
        var divEnd = Math.ceil( $(divID).offset().top ) + $(divID).height();
        animatedScrollTo.scrollTo({
            position: divEnd,
            duration: 750,
            offset: headerHeight,
        });
    }
});

// Giveaway-entries - scroll to entry ('anchor')
if (document.querySelector('body').classList.contains('giveaway')) {
    var path = window.location.pathname;
    var array = path.split('/');
    var divID = array[array.length-2]
    var entry = (document.querySelector('[data-slug="id-' + divID + '"]'));

    if (entry) {
        var divPos = Math.ceil( $(entry).offset().top );
        animatedScrollTo.scrollTo({
            position: divPos,
            duration: 1000,
            offset: 80,
            callback: function() {
                var control = entry.querySelector('.js-accordion-control');
                var target = document.getElementById(control.getAttribute('aria-controls'));
                Accordion.expand(control, target);
            }
        });
    }
}

// Load-more
const ajaxLists = [...document.querySelectorAll('.ajax-container')];
ajaxLists.forEach((ajaxList) => {
    ajaxList.addEventListener('click', (e) => {
        const loadMoreBtn = ajaxList.querySelector('.js-load-more');
        const loadMore = loadMoreBtn.parentNode;
        if (e.target == loadMoreBtn) {
            e.preventDefault();
            const url = loadMoreBtn.getAttribute('href');
            loadData(url).then(function (data) {
                var html;
                if (typeof(data) == 'string') {
                    html = data;
                } else if (typeof(data) == 'object') {
                    html = data.html;
                }
                loadMore.parentNode.removeChild(loadMore);
                ajaxList.appendChild(htmlToFragment(html));
                LazyLoad.loadAll(ajaxList);
            });
        }
    });
});

// Ajax tabs
[...document.querySelectorAll('.js-ajax-tab-area')].forEach(function(tabArea) {
    var tabs = [...tabArea.querySelectorAll('.js-ajax-tab')];
    var tabContent = tabArea.querySelector('.js-ajax-tab-container');

    tabs.forEach(function(tab) {
        tab.addEventListener('click', function(e) {
            e.preventDefault();
            if (e.target.classList.contains('active'))
                return

            tabs.forEach(function(tab) {
                tab.classList.remove('active');
            });
            this.classList.add('active');
            var url = this.getAttribute('href');
            var ajaxUrl = this.getAttribute('data-ajax-href');
            if (!ajaxUrl) {
                ajaxUrl = url;
            }
            loadData(ajaxUrl).then(function (data) {
                var html;
                if (typeof(data) == 'string') {
                    html = data;
                } else if (typeof(data) == 'object') {
                    html = data.html;
                }
                tabContent.innerHTML = html;
                LazyLoad.loadAll(tabContent);
                history.pushState(null, null, url);
                requestAnimationFrame(function() {
                    Accordion.constructor();
                    window.Franck_modal.bindOpenModal();
                })
            });
        });
    });
});

// Tabs
[...document.querySelectorAll('.js-tab-area')].forEach(function(tabArea) {
    var tabs = [...tabArea.querySelectorAll('.js-tab')];
    var tabContents = [...tabArea.querySelectorAll('.js-tab-content')];

    tabs.forEach(function(tab) {
        tab.addEventListener('click', function(e) {
            e.preventDefault();

            var tab_id = this.getAttribute('href');

            tabs.forEach(function(tab) {
                tab.classList.remove('active');
            });
            tabContents.forEach(function(tabContent) {
                tabContent.classList.remove('active');
            });

            this.classList.add('active');
            tabArea.querySelector(tab_id).classList.add('active');

            LazyLoad.loadAll(tabArea.querySelector(tab_id));


            if (tab.classList.contains('js-tab-carousel')) {
                mediaIndicator.dispatchEvent(changeEvent);
            };
        })
    })
});

// Tabs Dropdown
[...document.querySelectorAll('.dropdown')].forEach(function(dropdown) {
    var container = dropdown.querySelector('.inner');
    var active = dropdown.querySelector('.active');
    var closed = true;

    window.addEventListener('mediaChange', resetDropdown);
    container.addEventListener('click', function(e) {
        useDropdown(e);
    });

    resetDropdown();

    function resetDropdown() {
        container.style.height = active.offsetHeight + 'px';
        if (!dropdown.classList.contains('closed')) {
            dropdown.classList.add('closed');
            closed = true;
            container.style.height = active.offsetHeight + 'px';
        };
    };

    function useDropdown(e) {
        if (window.matchMedia('(max-width: 767px)').matches) {
            e.preventDefault;
            active = dropdown.querySelector('.active');
            if (e.target.nodeName !== 'A')
                return
            if (e.target == active) {
                e.preventDefault();
                toggleClosed(active);
            } else {
                [...dropdown.querySelectorAll('a')].forEach((tab) => {tab.classList.remove('active')});
                e.target.classList.add('active');
                toggleClosed(active);
            }
        }
    }


    function toggleClosed(active) {
        if (closed) {
            dropdown.classList.remove('closed');
            closed = false;
            container.style.height = container.scrollHeight + 'px';
        } else {
            dropdown.classList.add('closed');
            closed = true;
            container.style.height = active.offsetHeight + 'px';
        }
    }
});


// Back to top
if (document.querySelector('.js-back-to-top')) {
    // offset from top where the button becomes visible
    var offset = 500;

    // hides/shows button
    window.addEventListener('scroll', function() {
        var bodyScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if ( bodyScrollTop > offset ) {
            document.querySelector('.js-back-to-top').classList.add('is-visible');
        } else {
            document.querySelector('.js-back-to-top').classList.remove('is-visible');
        }
    });

    // scroll to top
    $('.js-back-to-top').click(function(event) {
        event.preventDefault();
        animatedScrollTo.scrollTo({
            position: 0,
            duration: 1000
        });
    });
}

// Footer newsletter
$('.footer-newsletter').on('submit', function(e) {
    e.preventDefault();

    modal.openModal({
        modalType: 'window',
        contentNode: document.querySelector('#newsletter-modal'),
        callback: callbacks['newsletterModal']
    })
});

// Cookies-accepted
// if (!(document.cookie.includes('cookies_accepted'))) {
//     var cookieBanner = document.querySelector('.cookie-banner');
//     cookieBanner.classList.add('open');

//     var closeBtn = cookieBanner.querySelector('.close-btn');
//     closeBtn.addEventListener('click', function() {
//         cookieBanner.classList.remove('open');
//     })

//     function setCookie(cname, cvalue, exdays) {
//         var d = new Date();
//         d.setTime(d.getTime() + (exdays*24*60*60*1000));
//         var expires = "expires="+ d.toUTCString();
//         document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//     }

//     setCookie('cookies_accepted', true, ((10*365)+2));
// }

// Resize listener
var mediaIndicator = document.getElementById('js-media-indicator');
var mediaQuery = window.getComputedStyle(mediaIndicator, ':before').content;
var windowWidth = window.innerWidth;
var changeEvent = new window.CustomEvent('mediaChange', {
    bubbles: true
});
window.addEventListener('resize', debounce(function() {
    var newMediaQuery = window.getComputedStyle((document.getElementById('js-media-indicator')), ':before').content;
    var newWindowWidth = window.innerWidth;

    if (mediaQuery != newMediaQuery) {
        LazyLoad.reloadImages(document.querySelector('body'));
        mediaIndicator.dispatchEvent(changeEvent);
    }

    mediaQuery = newMediaQuery;
    windowWidth = newWindowWidth;
}, 400));
